import { Inject, Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { GlobalRoles, Session } from '@parashift/shared/models';
import { betaFeatures, betaFeaturesDefaultValues, legacyFeatures, oldBetaFeatures } from '@parashift/shared/constants';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

const LOCAL_STORAGE_IDENTIFIER = 'rae_session';
const SESSION_TOKEN_IDENTIFIER = 'ps_session_token';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  session: Session = {
    auth: {
      expires_at: 0,
      jwt: '',
      login_timestamp: null
    },

    user: {
      api_key: false,
      disabled: false,
      email: null,
      global_roles: [],
      name: null,
      id: null,
      language: 'en',
      last_used_tenant_id: null,
      settings: {},
      tenants: [],
      unconfirmed_email: null,
      confirmed_at: null,
      user: false
    },

    skipped_documents: {
      separation: [],
      classification: [],
      extraction: [],
      qc: []
    },

    filters: {},
    user_filters: {},
    last_filter_change: 0,
    global_page_size: this.environment.list_default_page_size,
    user_navigation: {},
    navigation_active: '',
    primary_open: true,
    layout: {},
    views: {},
    mdt_region: 'ZZ'
  };

  constructor(@Inject(ENVIRONMENT_CONFIG) private environment: any) {
    this.getLocalStorage();
  }

  destroySession() {
    this.session = {
      auth: {
        expires_at: 0,
        jwt: '',
        login_timestamp: null
      },
      user: {
        api_key: false,
        disabled: false,
        email: null,
        global_roles: [],
        id: null,
        language: 'en',
        last_used_tenant_id: null,
        settings: {},
        tenants: [],
        unconfirmed_email: null,
        confirmed_at: null,
        updated_at: '',
        user: false,
        name: null
      },
      skipped_documents: {
        separation: [],
        classification: [],
        extraction: [],
        qc: []
      },
      filters: this.session.filters,
      user_filters: this.session.user_filters,
      last_filter_change: 0,
      global_page_size: this.session.global_page_size || this.environment.list_default_page_size,
      user_navigation: this.session.user_navigation,
      navigation_active: '[]',
      primary_open: true,
      layout: this.session.layout,
      views: this.session.views,
      mdt_region: this.session.mdt_region
    };

    this.setLocalStorage();
    this.setSessionStorage(SESSION_TOKEN_IDENTIFIER, {});
  }

  get auth() {
    return this.session.auth;
  }

  set auth(value) {
    this.session.auth = value;
    this.setLocalStorage();
  }

  get user() {
    return this.session.user;
  }

  set user(value) {
    delete value.settings;

    this.session.user = value;
    this.setLocalStorage();
  }

  get filters() {
    if (this.filter_change_diff > 3600000) {
      this.filters = {};
    }

    return this.session.filters;
  }

  set filters(value) {
    this.session.filters = value;
    this.session.last_filter_change = new Date().getTime();
    this.setLocalStorage();
  }

  get user_filters() {
    return this.session.user_filters;
  }

  set user_filters(value) {
    this.session.user_filters = value;
    this.setLocalStorage();
  }

  get user_navigation() {
    return this.session.user_navigation;
  }

  set user_navigation(value) {
    this.session.user_navigation = value;
    this.setLocalStorage();
  }

  get navigation_active() {
    return this.session.navigation_active;
  }

  set navigation_active(value) {
    this.session.navigation_active = value;
    this.setLocalStorage();
  }

  get primary_open() {
    return this.session.primary_open;
  }

  set primary_open(value) {
    this.session.primary_open = value;
    this.setLocalStorage();
  }

  get language() {
    return this.session.user.language;
  }

  set language(value) {
    this.session.user.language = value;
    this.setLocalStorage();
  }

  get layout() {
    return this.session.layout;
  }

  set layout(value) {
    this.session.layout = value;
    this.setLocalStorage();
  }

  get login_timestamp() {
    return this.session.auth.login_timestamp;
  }

  set login_timestamp(value) {
    this.session.auth.login_timestamp = value;
    this.setLocalStorage();
  }

  get views() {
    return this.session.views;
  }

  set views(value) {
    this.session.views = value;
    this.setLocalStorage();
  }

  get global_token(): string {
    return this.session.auth.jwt;
  }

  set global_token(value) {
    this.session.auth.jwt = value;
    this.setLocalStorage();
  }

  get session_token(): string {
    return this.getSessionStorage(SESSION_TOKEN_IDENTIFIER)?.token;
  }

  set session_token(value) {
    this.refreshSessionToken(value);
  }

  get expires_at(): any {
    return this.session.auth.expires_at;
  }

  set expires_at(value) {
    this.session.auth.expires_at = value;
    this.setLocalStorage();
  }

  get session_token_expires_at(): string {
    return this.getSessionStorage(SESSION_TOKEN_IDENTIFIER)?.expires_at;
  }

  get user_id() {
    return this.session.user.id;
  }

  set user_id(value) {
    this.session.user.id = value;
    this.setLocalStorage();
  }

  get user_name() {
    return this.session.user.name;
  }

  set user_name(value) {
    this.session.user.name = value;
    this.setLocalStorage();
  }

  get user_email() {
    return this.session.user.email;
  }

  set user_email(value) {
    this.session.user.email = value;
    this.setLocalStorage();
  }

  get show_sticky_fields() {
    return this.session.user.settings && this.session.user.settings.show_sticky_fields || false;
  }

  set show_sticky_fields(value) {
    const settings = this.session.user.settings || {};
    settings.show_sticky_fields = value;
    this.session.user.settings = settings;
    this.setLocalStorage();
  }

  get paginate_highlighting() {
    return this.session.user.settings && this.session.user.settings.paginate_highlighting || false;
  }

  set paginate_highlighting(value) {
    const settings = this.session.user.settings || {};
    settings.paginate_highlighting = value;
    this.session.user.settings = settings;
    this.setLocalStorage();
  }

  get global_page_size() {
    return this.session.global_page_size || this.environment.list_default_page_size;
  }

  set global_page_size(value) {
    this.session.global_page_size = value;
    this.setLocalStorage();
  }

  get stay_with_document() {
    return this.session.user.settings?.stay_with_document || false;
  }

  set stay_with_document(value) {
    const settings = this.session.user.settings || {};
    settings.stay_with_document = value;
    this.session.user.settings = settings;
    this.setLocalStorage();
  }

  get show_complete_validation_popup() {
    return this.session.user.settings && Object.prototype.hasOwnProperty.call(this.session.user.settings, 'show_complete_validation_popup') ? this.session.user.settings.show_complete_validation_popup : true;
  }

  set show_complete_validation_popup(value) {
    const settings = this.session.user.settings || {};
    settings.show_complete_validation_popup = value;
    this.session.user.settings = settings;
    this.setLocalStorage();
  }

  get beta_features() {
    return this.session.layout['beta_features'] || {};
  }

  get all_beta_features() {
    return betaFeatures;
  }

  get beta_features_default_values() {
    return betaFeaturesDefaultValues;
  }

  get legacy_features() {
    return this.session.layout['legacy_features'] || {};
  }

  get all_legacy_features() {
    return legacyFeatures;
  }

  get login_diff() {
    if (this.session.auth.login_timestamp) {
      return new Date().getTime() - this.session.auth.login_timestamp;
    }

    return null;
  }

  get filter_change_diff() {
    return new Date().getTime() - this.session.last_filter_change;
  }

  get global_roles(): GlobalRoles[] {
    return this.session.user.global_roles;
  }

  get userIsAdminSupportOrSales(): boolean {
    return  this.userHasRole('admin') || this.userHasRole('support') || this.userHasRole('sales');
  }

  get skipped_documents() {
    return this.session.skipped_documents;
  }

  set skipped_documents(value) {
    this.session.skipped_documents = value;
    this.setLocalStorage();
  }

  get list_view_mode() {
    return this.session.layout['list_view_mode'] || 'standard';
  }

  set list_view_mode(mode: 'standard' | 'expert') {
    this.session.layout['list_view_mode'] = mode;
    this.setLocalStorage();
  }

  get new_line_on_enter() {
    return this.session.layout['table_input']?.new_line_on_enter === false ? false : true;
  }

  set new_line_on_enter(value: boolean) {
    this.session.layout['table_input'] = { ...(this.session.layout['table_input'] ?? {}), new_line_on_enter: value };
    this.setLocalStorage();
  }

  get sticky_infobox() {
    return this.session.layout['infobox']?.sticky_infobox === false ? false : true;
  }

  set sticky_infobox(value: boolean) {
    this.session.layout['infobox'] = { ...(this.session.layout['infobox'] ?? {}), sticky_infobox: value };
    this.setLocalStorage();
  }

  get jump_to_highlight() {
    return this.session.layout['viewer']?.jump_to_highlight === false ? false : true;
  }

  set jump_to_highlight(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), jump_to_highlight: value };
    this.setLocalStorage();
  }

  get show_sidebar() {
    return this.session.layout['viewer']?.show_sidebar === false ? false : true;
  }

  set show_sidebar(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), show_sidebar: value };
    this.setLocalStorage();
  }

  get show_connection_line() {
    return this.session.layout['viewer']?.show_connection_line === false ? false : true;
  }

  set show_connection_line(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), show_connection_line: value };
    this.setLocalStorage();
  }

  get recognition_token_highlighting() {
    return this.session.layout['viewer']?.recognition_token_highlighting === true ? true : false;
  }

  set recognition_token_highlighting(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), recognition_token_highlighting: value };
    this.setLocalStorage();
  }

  get aggressive_highlight() {
    return this.session.layout['viewer']?.aggressive_highlight === true ? true : false;
  }

  set aggressive_highlight(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), aggressive_highlight: value };
    this.setLocalStorage();
  }

  get passive_highlight() {
    return this.session.layout['viewer']?.passive_highlight === false ? false : true;
  }

  set passive_highlight(value: boolean) {
    this.session.layout['viewer'] = { ...(this.session.layout['viewer'] ?? {}), passive_highlight: value };
    this.setLocalStorage();
  }

  get field_status() {
    return this.session.layout['field_info_box']?.field_status ?? false;
  }

  set field_status(value: boolean) {
    this.session.layout['field_info_box'] = { ...(this.session.layout['field_info_box'] ?? {}), field_status: value };
    this.setLocalStorage();
  }

  get field_help() {
    return this.session.layout['field_info_box']?.field_help ?? false;
  }

  set field_help(value: boolean) {
    this.session.layout['field_info_box'] = { ...(this.session.layout['field_info_box'] ?? {}), field_help: value };
    this.setLocalStorage();
  }

  get text_zoom() {
    return this.session.layout['field_info_box']?.text_zoom ?? false;
  }

  set text_zoom(value: boolean) {
    this.session.layout['field_info_box'] = { ...(this.session.layout['field_info_box'] ?? {}), text_zoom: value };
    this.setLocalStorage();
  }

  get recognition_confidence() {
    return this.session.layout['field_info_box']?.recognition_confidence ?? false;
  }

  set recognition_confidence(value: boolean) {
    this.session.layout['field_info_box'] = { ...(this.session.layout['field_info_box'] ?? {}), recognition_confidence: value };
    this.setLocalStorage();
  }

  get prediction_confidence() {
    return this.session.layout['field_info_box']?.prediction_confidence ?? false;
  }

  set prediction_confidence(value: boolean) {
    this.session.layout['field_info_box'] = { ...(this.session.layout['field_info_box'] ?? {}), prediction_confidence: value };
    this.setLocalStorage();
  }

  get parashift_powers() {
    return this.session.layout['parashift_powers'] ?? false;
  }

  set parashift_powers(value: boolean) {
    this.session.layout['parashift_powers'] = value;
    this.setLocalStorage();
  }

  get mdt_region() {
    return this.session.mdt_region || 'ZZ';
  }

  set mdt_region(value) {
    this.session.mdt_region = value;
    this.setLocalStorage();
  }

  setLoginTimestamp() {
    this.session.auth.login_timestamp = new Date().getTime();
    this.setLocalStorage();
  }

  refreshGlobalToken(token: string) {
    if (token) {
      const jwt = jwtDecode<JwtPayload>(token);
      this.session.auth.jwt = token;
      this.session.auth.expires_at = jwt.exp;
      this.setLocalStorage();
    }
  }

  refreshSessionToken(token: string) {
    if (token) {
      const jwt = jwtDecode<JwtPayload>(token);
      this.session.auth.jwt = token;
      this.session.auth.expires_at = jwt.exp;
      const auth = {
        token,
        expires_at: jwt.exp
      };

      this.setSessionStorage(SESSION_TOKEN_IDENTIFIER, auth);
    }
  }

  destroyTokens() {
    this.session.auth = {
      expires_at: 0,
      jwt: '',
      login_timestamp: null
    };
    this.setLocalStorage();
    this.setSessionStorage(SESSION_TOKEN_IDENTIFIER, {});
  }

  userHasRole(role: GlobalRoles): boolean {
    return this.global_roles.includes(role);
  }

  setBetaFeature(feature: typeof betaFeatures[number], value: boolean) {
    const { beta_features = {} } = this.session.layout;

    if (beta_features[feature] === undefined) {
      this.session.layout['beta_features'] = {...beta_features, [feature]: value };
    } else {
      this.session.layout['beta_features'][feature] = value;
    }
    this.handleBetaExceptions(feature, value);
    this.setLocalStorage();
  }

  setLegacyFeature(feature: typeof legacyFeatures[number], value: boolean) {
    const { legacy_features = {} } = this.session.layout;

    if (legacy_features[feature] === undefined) {
      this.session.layout['legacy_features'] = { ...legacy_features, [feature]: value };
    } else {
      this.session.layout['legacy_features'][feature] = value;
    }
    this.setLocalStorage();
  }

  isBetaFeatureEnabled(betaFeature: typeof betaFeatures[number]): boolean {
    const { beta_features = {} } = this.session.layout;
    return Object.keys(beta_features).length > 0 && beta_features[betaFeature] === true;
  }

  isLegacyFeatureEnabled(legacyFeature: typeof legacyFeatures[number]): boolean {
    const { legacy_features = {} } = this.session.layout;
    return Object.keys(legacy_features).length > 0 && legacy_features[legacyFeature] === true;
  }

  private handleBetaExceptions(feature: typeof betaFeatures[number] | typeof oldBetaFeatures[number], value: boolean) {
    switch (feature) {
      case 'lists': {
        if (!value) {
          this.global_page_size = this.environment.list_default_page_size;
        }
      }
    }
  }

  private getLocalStorage() {
    this.session = JSON.parse(localStorage.getItem(LOCAL_STORAGE_IDENTIFIER)) || this.session;
    return this.session;
  }

  private setLocalStorage() {
    setTimeout(() => {
      localStorage.setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(this.session));
    });
  }

  private getSessionStorage(identifier: string) {
    return JSON.parse(sessionStorage.getItem(identifier)) ?? {};
  }

  private setSessionStorage(identifier: string, item: object) {
    setTimeout(() => {
      sessionStorage.setItem(identifier, JSON.stringify(item));
    });
  }
}
